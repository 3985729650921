/* SidebarItemLink.css */
.logo-link img {
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
}

@media (min-width: 768px) {
  .toggle-sidebar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 8px;
  }
}
/* Media query for 'lg:hidden' */
@media (min-width: 767px) {
  .toggle-sidebar-button {
    display: none;
  }
  .my_sidebar {
    display: none;
  }
}
.sidebar-item-link {
  display: inline-flex !important;
  justify-content: space-between !important;
  padding: 8px 16px !important;
  border-radius: 6px !important;
  font-weight: 500;
  align-items: center !important;
  gap: 8px !important;
  width: 100%;
  height: auto;
  transition: colors 0.2s ease-in-out, transform 0.2s ease-in-out;
  outline: none;
  pointer-events: auto;
  opacity: 1;
}

.sidebar-item-link.bg-accent {
  color: #dedf1b;
}

.sidebar-item-link:hover {
  color: #dedf1b;
}

.sidebar-item-link.focus-visible {
  ring: 2px solid #dedf1b;
  ring-offset: 2px;
}

.sidebar-item-link.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.chevron-rotate {
  height: 16px;
  width: 16px;
  transition: transform 0.2s;
}

.chevron-rotate.active {
  transform: rotate(180deg);
}

.overflow-hidden-space-y-1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 4px; /* This approximates Tailwind's space-y-1 */
}
